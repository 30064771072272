import '../styles/main.scss';

import mySwiper from './modules/mySwiper'
import mForm from './modules/form'
import FormSwitcher from './modules/jsSwitchForm'
import goto from './modules/goto'
import hmenu from './modules/menu-hamburger'
import lang from './modules/lang'
import video from './modules/video'

// import './templates/parts/header.hbs';

// Ваш JavaScript-код

document.addEventListener('DOMContentLoaded', function() {
    lang.init()
    new mySwiper('.top-swiper', {
        swiperOptions: {
            navigation: false,
            Thumbs: false,
            loop: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
        }
    })
    new mySwiper('.rev__swiper', {
        swiperOptions: {
            navigation: true,
            Thumbs: false,
            loop: false,
            autoplay: false,
            navigation: {
                prevEl: '.rev__swiper-btn--prev',
                nextEl: '.rev__swiper-btn--next',
            },
            pagination: {
                el: ".rev__swiper-pagination",
                dynamicBullets: true,
              },
        }
    })

    new mForm()
    new mForm({
        container: '.js-form-teacher',
        phone: false,
        sibmitBtn: '.js-submit-teacher',
        isValid: true,
    })
    FormSwitcher.init()
    goto.init()
    hmenu.init()

    video.init()
});




