export default {
    init() {
        this.cache()
        this.events()
    },
    cache() {
        this.settings = {
            btns: '.js-goto',
        } 
        this.$btns = document.querySelectorAll(this.settings.btns)
    },
    events() {
        this.$btns.forEach((btn) => {
            btn.addEventListener('click', this.goTo.bind(this))
        })
    },
    goTo(e) {
        e.preventDefault()
        const target = e.target
        const taregetSelector = target.dataset.target || target.getAttribute('href')
        
        if(!taregetSelector) return
        const toTarget = document.querySelector(taregetSelector)
        if(!toTarget) return
        window.scrollTo({ top: toTarget.offsetTop, behavior: 'smooth' })
    }
}