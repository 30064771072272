import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination, Controller } from 'swiper/modules';

export default class swiper {

    constructor(selector, options) {
        this.domElement = document.querySelector(selector)
        if (!this.domElement) return
        this.cache(options)
    }


    cache(options) {
        const $slider = this.domElement
        if(!$slider) return
        this.$slider = new Swiper($slider, {...this.swiperOptions, ...options.swiperOptions})
    }

    get swiperOptions () {
        return  {
            modules: [Autoplay, Navigation, Pagination, Controller],
            autoHeight: true,    
            navigation: {
                prevEl: '.rev__slider--prev',
                nextEl: '.rev__slider--next',
            },
            Thumbs: {
                swiper: this.$navSlider
            }
       }
    }
} 