export default {
    init() {
        this.cache()
        this.events()
    },
    cache() {
        this.settings = {
            content: '.header-lang',
            btn: 'button',
            active: 'active',
        } 
        this.$content = document.querySelector(this.settings.content)
        this.$btn = this.$content.querySelector(this.settings.btn)
    },
    events() {
        this.$btn.addEventListener('click', this.toggleLang.bind(this))
    },
    toggleLang() {
        this.$content.classList.toggle(this.settings.active)
    }
}