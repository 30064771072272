export default {
    init() {
        this.cache()
        this.events()
    },
    cache() {
        this.settings = {
            btn: '.js-switch-form__btn',
            form: '.js-switch-form__form',
            btns: '.js-switch-form__form-btn',
            hidden: 'd-none'
        } 
        this.$btn = document.querySelector(this.settings.btn)
        this.$forms = document.querySelectorAll(this.settings.form)
        this.$btns = document.querySelectorAll(this.settings.btns)
    },
    events() {
        this.$btn.addEventListener('click', this.switchForms.bind(this))
    },
    switchForms(e) {
        const 
            $btn = e.target,
            text = $btn.dataset.text,
            currtext =  $btn.textContent
            $btn.textContent = text
            $btn.dataset.text = currtext
        this.$forms.forEach((form) => {
            form.classList.toggle(this.settings.hidden)
        })
        this.$btns.forEach((btn) => {
            btn.classList.toggle(this.settings.hidden)
        })
    }
}