import intlTelInput from 'intl-tel-input';
import parsePhoneNumber from 'libphonenumber-js'
import utils from 'intl-tel-input/build/js/utils';

export default class mForm {

    constructor(options) {
        this.cache(options)
        this.events()
    }
    cache(options) {
        this.settings = {
            ...this.defOptions, ...options
        }

        this.$form = document.querySelector(this.settings.container)
        this.$phone = this.settings.phone ? this.$form.querySelector(this.settings.phone) : false
        this.$submit = document.querySelector(this.settings.sibmitBtn)
        this.$success = document.querySelector(this.settings.success)
        this.$error = document.querySelector(this.settings.error)
        console.log(this.$form);
        if(this.$phone) {
            this.iti = intlTelInput(this.$phone, {
                separateDialCode: true,
                utilsScript: utils,
                initialCountry: "us",
            });
            this.HandlPhoneInput()
        }
    }
    get defOptions () {
        return {
            container : '.js-form',
            phone : '#phone',
            sibmitBtn: '.js-submit',
            isValid: false,
            success: '.js-success',
            error: '.js-error',
            hidden: 'd-none'
        }
    }
    events() {
        if(this.$phone) this.$phone.addEventListener('input', this.HandlPhoneInput.bind(this));
        this.$submit.addEventListener('click', this.HandlSubmit.bind(this));
    }
    HandlPhoneInput() {
            this.isValid = this.iti.isValidNumber();
            if(this.$phone.value[0] != '+' && this.$phone.value.length > 0) {
                this.$phone.value = '+' + this.$phone.value
            }
            if (this.isValid) {
                this.$submit.disabled = false
            } else {
                this.$submit.disabled = true
            }
    }
    HandlSubmit() {
        if(!this.$phone) this.SubmitForm()
        if ( this.isValid) {
            this.SubmitForm()
        }
    }
    SubmitForm() {
        const formData = new FormData(this.$form);
        if(this.$phone) {
            const phoneNumber = parsePhoneNumber(this.iti.getNumber(), this.iti.getSelectedCountryData().iso2);
            const phone = phoneNumber.formatInternational();
            formData.append('phone', phone);
        }

        this.submit(formData).then((response) => {
            
            // this.$success.classList.remove(this.settings.hidden)
            // this.$error.classList.add(this.settings.hidden)
            debugger
            // window.location.href = '../success.html';

        }).catch((error) => {
            this.$error.classList.remove(this.settings.hidden)
            this.$success.classList.add(this.settings.hidden)            
        }).finally(() => {
            this.$form.reset();
            this.$form.classList.add(this.settings.hidden)
        })

        setTimeout(() => {
            this.$form.classList.remove(this.settings.hidden)
            this.$success.classList.add(this.settings.hidden)            
            this.$error.classList.add(this.settings.hidden)
        }, 5000);
    }
    submit(data) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '../assets/mail.php', true);
            xhr.send(data);
            xhr.onreadystatechange = function () {
                console.log(xhr);
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(xhr.response);
                    } else {
                        reject(xhr.response);
                    }
                }
            };
        })
        
    }
}