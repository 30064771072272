export default {
    init() {
        this.cache()
        this.events()
    },
    cache() {
        this.settings = {
            container:  '.videos',
            isLiaded: false
        } 
        this.$container = document.querySelector(this.settings.container)
        this.loadVideoIfVisible()
    },
    events() {
        document.addEventListener('scroll', this.loadVideoIfVisible.bind(this));
    },

    loadVideoIfVisible() {
        if (this.isElementInViewport && !this.settings.isLiaded) {
            console.log(this.isElementInViewport && !this.settings.isLiaded);
            this.addVideos()
            this.settings.isLiaded = true
          }
    },

    addVideos() {
        this.$container.querySelectorAll('video').forEach($video => {
            $video.src = $video.dataset.src
        })
    },

    get isElementInViewport() {
        const offsetTop = this.$container.offsetTop
        const screen  = ((window.innerHeight || document.documentElement.clientHeight) +( window.scrollY || window.pageYOffset))
        return (
            offsetTop < screen 
        );
      }
  
}