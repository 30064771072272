export default {
    init() {
        this.cache()
        this.events()
    },
    cache() {
        this.settings = {
            btn: '.hamburger',
            active: 'is-active',
            menu: '.menu',
        } 
        this.$btn = document.querySelector(this.settings.btn)
        this.$menu = document.querySelector(this.settings.menu)

    },
    events() {
        this.$btn.addEventListener('click', this.HandlHunClick.bind(this))
        document.addEventListener('click', this.HandlDocumentClick.bind(this))
    },
    HandlHunClick() {
        this.toggleMenu()
    },
    HandlDocumentClick(e) {
        if (!this.$btn.contains(e.target) && !this.$menu.contains(e.target) || e.target.classList.contains('js-goto')) {
            this.closeMenu()
        }
    },
    toggleMenu() {
        this.$btn.classList.toggle(this.settings.active)
        this.$menu.classList.toggle(this.settings.active)
    },
    closeMenu() {
        this.$btn.classList.remove(this.settings.active)
        this.$menu.classList.remove(this.settings.active)
    }


}